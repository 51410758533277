import styled from "styled-components"

import { spacing } from "src/ui/spacing"

export const ColumnSortWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${spacing.S};

  &:hover {
    cursor: pointer;
  }
`

export const IconWrapper = styled.div<{ $up?: boolean }>`
  rotate: ${({ $up }) => ($up ? "180deg" : "0deg")};
`
export const UnsortedIconsWrapper = styled.div`
  display: grid;
  line-height: 0;
  opacity: 0.5;
  transition: opacity 0.2s;

  ${ColumnSortWrapper}:hover & {
    opacity: 1;
  }
`
